<template>
    <div>
        <v-dialog
                v-model="dialog"
                max-width="500"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        class="d-flex justify-end"
                >Tarif Löschen
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline grey lighten-2">
                    Tarif Löschen
                </v-card-title>

                <v-card-text>
                    Soll dieser Tarif <strong>{{tarif_model.name}}</strong> wirklich gelöscht werden?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn color="primary" text @click="dialog = false">Nein</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="removeTarif">Ja, löschen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-spacer class="mb-4"></v-spacer>
        <v-form>
            <v-text-field type="text"
                          v-model="tarif_model.link"
                          label="Link"
            >
            </v-text-field>
            <v-select
                    v-model="tarif_model.anbieter_id"
                    :items="anbieter"
                    item-value="id"
                    label="Anbieter"
                    required
            >
                <template v-slot:item="{item}">
                    {{item.name}}
                </template>
                <template v-slot:selection="{ item }">
                    {{item.name}}
                </template>
            </v-select>
            <v-text-field
                    v-model="tarif_model.name"
                    label="Name"
                    required
            ></v-text-field>
            <v-row>
                <v-col cols="6">
                    <v-text-field
                            v-model.number="tarif_model.grundpreis"
                            label="Grundpreis"
                            type="number"
                            suffix="€"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                            v-model.number="tarif_model.installations_preis"
                            label="Installationspreis"
                            type="number"
                            suffix="€"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <v-text-field
                            v-model.number="tarif_model.bandbreite_down"
                            label="Bandbreite Down"
                            type="number"
                            suffix="MBit/s"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                            v-model.number="tarif_model.bandbreite_up"
                            label="Bandbreite Up"
                            type="number"
                            suffix="MBit/s"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-text-field
                    v-model.number="tarif_model.vertragslaufzeit"
                    label="Vertragslaufzeit"
                    type="number"
                    suffix="Monate"
                    required
            ></v-text-field>
            <v-row>
                <v-col cols="6">
                    <v-text-field
                            v-model.number="tarif_model.effektiv_preis2"
                            label="Effektivpreis 2 Jahre"
                            type="number"
                            suffix="€"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                            v-model.number="tarif_model.effektiv_preis5"
                            label="Effektivpreis 5 Jahre"
                            type="number"
                            suffix="€"
                            required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-text-field v-model="tarif_model.region" label="Verfügbar in Region"></v-text-field>
            <v-checkbox
                    v-model="tarif_model.is_flat"
                    label="Flatrate"
            ></v-checkbox>
            <v-row>
                <v-col cols="2">
                    <v-checkbox
                            v-model="tarif_model.is_dsl"
                            label="DSL"
                    ></v-checkbox>
                </v-col>
                <v-col cols="2">
                    <v-checkbox
                            v-model="tarif_model.is_cable"
                            label="Cable"
                    ></v-checkbox>
                </v-col>
                <v-col cols="2">
                    <v-checkbox
                            v-model="tarif_model.is_fiber"
                            label="Fiber"
                    ></v-checkbox>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2">
                    <v-checkbox
                            v-model="tarif_model.has_tv"
                            label="TV"
                    ></v-checkbox>
                </v-col>
                <v-col cols="2">
                    <v-checkbox
                            v-model="tarif_model.has_phone_flat"
                            label="Festnetzflat"
                    ></v-checkbox>
                </v-col>
                <v-col cols="2">
                    <v-checkbox
                            v-model="tarif_model.has_handy_flat"
                            label="Handyflat"
                    ></v-checkbox>
                </v-col>
            </v-row>
            <v-textarea
                    v-model="tarif_model.text"
                    label="Zusätzlicher Informationstext"
            >
            </v-textarea>
            <v-spacer></v-spacer>
            <v-btn block @click="saveTarif" color="primary">Speichern</v-btn>
            <v-spacer class="my-2"></v-spacer>
            <v-btn block @click="saveTarifAndBack" color="secondary">Speichern und Zurück zur Übersicht</v-btn>
        </v-form>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';

    export default {
        name: "TarifEdit",
        beforeDestroy() {
            this.$store.dispatch('calculator/clearModel')
        },
        computed: {
            anbieter: {
                get() {
                    return this.$store.$db().model('anbieter').query().all()
                }
            },
            ...mapGetters('oidcStore', [
                'oidcIsAuthenticated',
                'oidcAccessToken',
                'oidcError'
            ])
        },
        data() {
            return {
                tarif_model: this.tarif,
                dialog: null
            }
        },
        methods: {
            ...mapActions('oidcStore', [
                'authenticateOidcSilent', // Authenticates if signed in. No redirect is made if not signed in
            ]),
            async saveTarif() {
                if (!this.oidcIsAuthenticated) {
                    this.authenticateOidcSilent().then(() => this.saveTarif())
                    return
                }
                this.tarif_model.updated = new Date().toISOString()
                const model = this.$store.$db().model('tarif')
                await model.api().put(`/api/v1/tarif/${this.tarif_model.id}`, this.tarif_model, {
                    headers: {'Authorization': `Bearer ${this.oidcAccessToken}`}
                })
            },
            saveTarifAndBack() {
                this.saveTarif().then(() => this.$router.push({path: '/'}))
            },
            removeTarif() {
                const model = this.$store.$db().model('tarif')
                model.api().delete(`/api/v1/tarif/${this.tarif_model.id}`, {
                    headers: {'Authorization': `Bearer ${this.oidcAccessToken}`}
                }).then(() => {
                    this.dialog = false
                    model.delete(this.tarif_model.id)
                    this.$router.push({path: '/tarife/'})
                })
            }
        },
        props: {
            tarif: Object
        }
    }
</script>

<style scoped>

</style>
