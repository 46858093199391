<template>
    <TarifEdit :tarif="tarif"></TarifEdit>
</template>

<script>
    import TarifEdit from '@/components/TarifEdit';

    export default {
        name: "EditTarif",
        components: {TarifEdit},
        computed: {
            tarif: {
                get() {
                    return this.$store.$db().model('tarif').query().find(this.$route.params.id)
                }
            }
        }
    }
</script>

<style scoped>

</style>
